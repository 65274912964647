import { Injectable } from "@angular/core";

import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../servicecs/auth.service";
import { AlertService } from "src/app/shared/services/alert.service";

@Injectable()
export class RoleGuardService  {
  constructor(public auth: AuthService, public alertService: AlertService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const user = this.auth.getCurrentUser();
    let hasAccess = false;
    // const routePath = state.url.split(`/`).pop();
    user.roles.forEach((role) => {
      hasAccess = role.permissions.some((x) => x.url.includes(state.url));
    });
    if (!hasAccess) {
      this.alertService.alertInfo("Pristup zabranjen, kontaktirajte podršku!");
    }
    return hasAccess;
  }
}
